import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import ColorLogo from '../../assets/logo_color.svg';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import Facebook from './facebook';

const LogoImage = styled.img`
	position: absolute;
	top: 32px;
	left: 32px;
	width: 124px;
	// height: 91px;
	@media (max-width: 680px) {
		display: none;
	}
`;

const LoginWrapper = styled.div`
	display: flex;
	flex-direction: column;

	align-items: center;
	padding-top: 55px;
	padding-bottom: 55px;
	// height: 100vh;
	@media (min-width: 680px) {
		height: 100vh;
	}
`;

const SignLogWrapper = styled.h1`
	margin-bottom: 73px;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	text-align: center;
	font-size: 32px;
	color: #dedede;
`;

const LoginTab = styled.span`
	color: #fff;
	font-size: 18px;
	cursor: auto;
	position: relative;
	display: inline-block;
	padding: 8px 18px;
	border: #007bff solid 1px;
	border-radius: 5px 0px 0px 5px;
	background-color: #007bff;
	a:hover {
		text-decoration: none;
	}
`;

const SignupTab = styled.div`
	color: #fff;
	font-size: 18px;
	cursor: auto;
	position: relative;
	display: inline-block;
	padding: 8px 18px;
	border: #007bff solid 1px;
	border-radius: 0px 5px 5px 0px;
	a:hover {
		text-decoration: none;
	}
`;

const LoginText = styled.span`
	color: #000;
	cursor: auto;
	position: relative;
	display: inline-block;
	padding: 1rem;
	border: 1px solid #343a40;
	border-radius: 0.25rem;
`;

const SignupText = styled.div`
	position: relative;
	display: inline-block;
	cursor: pointer;
`;

const FormGroup = styled.div`
	margin-bottom: 20px;
	width: 350px;
`;

const InputLabel = styled.label`
	padding-left: 10px;
	margin-bottom: 10px;
	color: #000;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	font-size: 18px;
`;

const FormInput = styled.input`
	display: block;
	width: 100%;
	padding: 16px 10px;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	font-size: 22px;
	line-height: 28px;
	border: none;
	border-radius: 0;
	color: #000;
	background-color: #ebebeb;
	border: 2px solid #fff;
	&:focus {
		border: 2px solid #000;
		outline-width: 0;
	}
`;

const NewPasswordLinkWrapper = styled.div`
	padding-left: 10px;
	margin-bottom: 30px;
`;

const FormBtn = styled.button`
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	background-color: inherit;
	border: 2px solid #000;
	width: 110px;
	height: 50px;
	font-weight: bold;
	&:hover {
		color: #f8f8ff;
		background-color: #000;
	}
	@media (max-width: 680px) {
		font-family: -apple-system, BlinkMacSystemFont, sans-serif;
		background-color: inherit;
		border: 2px solid #000;
		width: 110px;
		height: 50px;
	}
`;

const ErrorWrapper = styled.div`
	color: #721c24;
	background-color: #f8d7da;
	border-color: #f5c6cb;
	padding: 0.75rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
`;

const LoginSideText = styled.p`
	color: #000;
	cursor: auto;
	// position: relative;
	padding: 2rem 0rem;
	font-size: 20;
	// font-weight: bold;
	text-align: left;
	line-height: 2 @media (max-width: 680px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 35px;
		padding-left: 1rem;
	}
`;

const SplitLeftCenter = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	// align-items: center;
	@media (max-width: 980px) {
		flex-direction: column;
		align-items: center;
		// border: 1px solid red;
	}
	// border: 1px solid blue;
`;
const LeftRightCenter = styled.div`
	width: 100%;
	// border: 1px solid red;
	justify-content: center;
	align-items: center;
	padding: 16px;
`;

const Login = props => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');

	const { loginUser } = useContext(AuthContext);

	const login = e => {
		e.preventDefault();
		if (email === '' || password === '') {
			return setError('Please include email and password.');
		}
		try {
			setError('');
			loginUser(email, password, props).then(res => {
				document.body.style.overflow = 'hidden';
				// let lr = fetch(
				// 	`${process.env.REACT_APP_BASE_URL}/analytics/addLoginRecord`,{
				// 		method: 'POST',
				// 		headers: {
				//          				'Content-Type': 'application/json', // <-- Specifying the Content-Type
				// 					},
				// 				body: JSON.stringify({email:`${email}`}) // <-- Post parameters
				// 			})
				// .then(response => response.json())
				// .then(lr => {
				// 	return lr;
				// });

				if (res && res.message && res.error === true) {
					setError(res.message);
				} else setError(null);
			});
		} catch (error) {
			console.log(error);
			props.history.push('/login');
		}
	};
	const location = useLocation();

	if (location.pathname == '/login') {
		document.body.style.overflow = 'auto';
	}

	return (
		<div>
			<LogoImage key='logo' src={ColorLogo} alt='Scripture Notes Icon' />
			<LoginWrapper key='loginFrom'>
				<SignLogWrapper>
					<LoginTab>Log in</LoginTab>
					<SignupTab>
						<Link to='/register'>Sign up</Link>
					</SignupTab>
				</SignLogWrapper>

				{/* <Facebook handleError={errorMessage => errorMessage && setError(errorMessage)} /> */}
				<SplitLeftCenter>
					<LeftRightCenter>
						<LoginSideText>
							Welcome to Scripture Notes! If you have an account, please sign in. If you don’t have an account, click Sign up and get
							registered to 10x your scripture study.
						</LoginSideText>
					</LeftRightCenter>
					<LeftRightCenter>
						<form onSubmit={login} style={{ marginTop: 25, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
							{error !== '' && (
								<ErrorWrapper>
									<p>{error}</p>
								</ErrorWrapper>
							)}

							<FormGroup>
								<InputLabel htmlFor='inputEmail'>Email</InputLabel>
								<FormInput
									name='email'
									type='email'
									id='inputEmail'
									value={email}
									onChange={e => setEmail(e.target.value)}
									autoFocus={false}
								/>
							</FormGroup>

							<FormGroup>
								<InputLabel htmlFor='inputPassword'>Password</InputLabel>
								<FormInput name='password' type='password' id='inputPassword' value={password} onChange={e => setPassword(e.target.value)} />
							</FormGroup>

							<NewPasswordLinkWrapper>
								<Link
									style={{
										color: '#000',
										fontFamily: 'OpenSansSemiBold',
										fontSize: '16px',
										lineHeight: '22px',
									}}
									to='/forgot'
								>
									Forgot your password?
								</Link>
							</NewPasswordLinkWrapper>

							<FormBtn onClick={e => login(e)} type='submit'>
								Log in
							</FormBtn>
						</form>
					</LeftRightCenter>
					{/* <LeftRightCenter></LeftRightCenter> */}
					<div />
				</SplitLeftCenter>
			</LoginWrapper>
		</div>
	);
};

export default Login;
