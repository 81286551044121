import { faArrowUp, faInfoCircle, faTimes } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState, useRef } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import Scrollbars from 'react-custom-scrollbars';
import { PortalWithState } from 'react-portal';
import Tooltip from 'react-tooltip-lite';
import styled from 'styled-components';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import { NavContext } from '../../ContextProviders/NavProvider';
import Loading from '../Helpers/Loading';
import { ClosePopUpBtn, DeletePopUpBtnWrapper, DeletePopUpWrapper } from '../Shared/SharedStyles';

const ClosePaneWrapper = styled.div`
	display: block;
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(178 183 190);
	&:hover {
		color: #1a56eb;
	}
`;

const PaneContainer = styled.div`
	margin: 0 30px 0 25px;
	padding: 15px 0 10px;
	padding-top: 35px;
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 25px - 30px);
	overflow: hidden;
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const PaneTitle = styled.h3`
	font-size: 22px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	touch-action: manipulation;
	line-height: 32px;
	margin: 0;
	padding: 0;
	color: #6d7886;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
const VerseTitle = styled.h4`
	font-size: 18px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	touch-action: manipulation;
	line-height: 32px;
	margin: 0;
	padding: 0;
	color: #324599;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const TitleDiv = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;
const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
const TopSection = styled.div`
	flex: 0 0 auto;
	height: 75px;
`;
const ResponseContainer = styled.div`
	width: 100%;
	flex: 1;
	background-color: #fff;
	border-radius: 10px;
	border: 1px solid #6d7886;
	padding: 10px;
`;
const ConversationContainer = styled.div`
	position: relative;
`;
const TextAreaContainer = styled.div`
	display: flex;
	flex: 0 0 auto;
	flex-direction: column;
	bottom: 15px;
	width: 103%;
	min-height: 100px;
	padding-right: 15px;
	padding-left: 0px;
`;

const SubmitButton = styled.button`
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 20px;
	border-radius: 10px;
	cursor: pointer;
	height: 30px;
	width: 30px;
	padding: 3px 3px;
	text-align: center;
	position: absolute;
	bottom: 35px;
	right: 5px;
	z-index: 2;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
	&:focus {
		outline: none;
	}
`;
const Response = styled.p`
	width: 100%;
	white-space: pre-wrap;
`;
const LoadingContainer = styled.div`
	height: 100px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	// background-color: #fff;
`;
const PromptsContainer = styled.div`
	position: absolute;
	bottom: 2.5px;
	z-index: 1;
`;
const Prompt = styled.p`
	background-color: #fff;
	border-radius: 10px;
	margin: 2.5px 5px;
	padding: 5px;
	color: #324599;
	border: 1px solid #324599;
	&:hover {
		background-color: #324599;
		color: #fff;
		cursor: pointer;
	}
`;
const NameSpan = styled.span`
	color: #324599;
	font-size: 16px;
	margin-right: 5px;
	font-weight: 700;
`;
const InfoSpan = styled.span`
	margin-left: 5px;
	&:hover {
		cursor: pointer;
	}
`;
const CopyButton = styled.button`
	margin-top: -6px;
	margin-right: 5px;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 12px;
	// line-height: 24px;
	border-radius: 10px;
	cursor: pointer;
	max-width: 155px;
	padding: 6px 12px;
	text-align: center;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
	&:focus {
		outline: none;
	}
`;
const CreateCnBtn = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px 5px;
	line-height: 10px;
	width: 30%;
	font-weight: 700;
	font-size: 15px;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	border-radius: 10px;
	cursor: pointer;
	max-width: 155px;
	// height: 80%;
	position: absolute;
	right: 5px;
	top: 35px;
	&:hover {
		background-color: #324599;
		color: rgb(255, 255, 255);
	}
	&:focus {
		outline: none;
	}
`;
const Question = styled.p`
	width: 100%;
	text-align: left;
`;
const openaiKey = process.env.REACT_APP_CHATGPT_KEY;
const assistantId = process.env.REACT_APP_CHATGPT_ASSISTANT_KEY;

const ChatGPT = props => {
	const { book, chapter, verseNumber, volume } = props.verse;
	// Global Storage
	const { isProUser } = useContext(AuthContext);
	const { dispatch, createCollection, handleOpenVerses } = useContext(NavContext);

	//State
	const [input, setInput] = useState('');
	const [response, setResponse] = useState(props.response ? props.response : '');
	const [doneGenerating, setDoneGenerating] = useState(false);
	const [thread, setThread] = useState([]);
	const [question, setQuestion] = useState(props.question ? props.question : '');
	const [threadId, setThreadId] = useState(props.threadId ? props.threadId : '');
	const [multiWordBooks, setMultiWordBooks] = useState(['Doctrine and Covenants', 'Song of Solomon', 'Book of Mormon']);
	const [responseLoading, setResponseLoading] = useState(false);

	const escapedBooks = multiWordBooks.map(book => book.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1'));
	const multiWordBooksPattern = escapedBooks.join('|');
	const verseRegex = new RegExp(`\\b((?:[1-4]?\\s?[A-Za-z]+|${multiWordBooksPattern}))\\s(\\d+):(\\d+)(?:-(\\d+))?\\b`, 'g');

	// const verseRegex = RegExp(`/\b((?:[1-3]?\s?[A-Za-z]+|(?:${multiWordBooksPattern})))(?:\s(\d+):(\d+)(?:-(\d+))?)\b/g`, 'g');
	// const verseRegex = /\b((?:[1-3]?\s?[A-Za-z]+|(?:Doctrine and Covenants|Song of Solomon|Book of Mormon)))(?:\s(\d+):(\d+)(?:-(\d+))?)\b/g;
	//Ref
	const scrollbarRef = useRef(null);
	const itemRefs = useRef({});
	//useEffects
	useEffect(() => {
		handleGreeting();
		fetchMultiWordBooks();
	}, []);
	// useEffect(() => {
	// 	if (thread.length > 1) {
	// 		scrollToKey(1);
	// 	}
	// }, [Object.keys(itemRefs.current).length]);
	//Methods
	const handleUpdatePane = (question, response, threadId) => {
		dispatch({ type: 'UPDATE_AI_PANE', payload: { paneKey: props.uniqueKey, question, response, threadId } });
	};
	const fetchMultiWordBooks = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/books/getAllBooks`, {
			method: 'GET',
		})
			.then(res => res.json())
			.then(res => {
				setMultiWordBooks([...res.map(book => book.title), 'D&C']);
			});
	};
	const handleGreeting = async () => {
		if (isProUser()) {
			!props.response &&
				setResponse(`Hello! I'm here to help you with any questions you may have about your scripture study. What can I assist you with today?`);
			// setThread([
			// 	{
			// 		role: 'assistant',
			// 		message: `Hello! I'm here to help you with any questions you may have about your scripture study. What can I assist you with today?`,
			// 	},
			// ]);
		} else {
			setResponse(
				'This feature is only available for Pro users. Please sign up for a pro membership in the account settings tab on the dashboard to access this feature'
			);
			// setThread([
			// 	{
			// 		role: 'assistant',
			// 		message: `This feature is only available for Pro users. Please sign up for a pro membership in the account settings tab on the dashboard to access this feature.`,
			// 	},
			// ]);
		}
	};
	const copyResponse = () => {
		const threadText = [{ role: 'assistant', message: response }, { role: 'user', message: question }, ...thread]
			.map(e => (e.role === 'user' ? `You: ${e.message}` : `Daniel: ${e.message}`))
			.reverse()
			.join('\n\n');

		navigator.clipboard.writeText(threadText);
	};
	const createCN = () => {
		const threadText = [{ role: 'assistant', message: response }, { role: 'user', message: question }, ...thread]
			.map(e => (e.role === 'user' ? `You: ${e.message}` : `Daniel: ${e.message}`))
			.reverse()
			.join('\n\n');
		const newResponse = threadText.replace(/(?:\r\n|\r|\n)/g, '<br>');
		createCollection(null, props.uniqueKey, `${newResponse}`);
	};
	const handleGetThread = async (prompt, id = threadId) => {
		await fetch(`https://api.openai.com/v1/threads/${id}/messages`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${openaiKey}`,
				'OpenAI-Beta': 'assistants=v2',
			},
		})
			.then(res => res.json())
			.then(res => {
				const { data } = res;
				const mappedData = data.map(item => {
					return { role: item.role, message: item.content[0].text.value };
				});
				setThread(mappedData.slice(2));
				setDoneGenerating(true);
				// console.log(mappedData.splice);
				setResponse(data[0].content[0].text.value);
				scrollbarRef.current.scrollToBottom();
				// handleUpdatePane(prompt, data[0].content[0].text.value, id);
				return;
			})
			.catch(err => console.log('Get thread err: ', err));
	};

	const handleRunThread = async (prompt, id = threadId) => {
		await fetch(`https://api.openai.com/v1/threads/${id}/runs`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${openaiKey}`,
				'OpenAI-Beta': 'assistants=v2',
			},
			body: JSON.stringify({
				assistant_id: assistantId,
				additional_instructions: book
					? `This thread will mostly about ${volume}, ${book} or ${book} chapter ${chapter} verse ${verseNumber}`
					: '',
				stream: true,
			}),
		})
			.then(async res => {
				setResponseLoading(false);
				const reader = res.body.getReader();
				const decoder = new TextDecoder('utf-8');
				let message = '';
				while (true) {
					const chunk = await reader.read();
					const { done, value } = chunk;
					if (done) {
						break;
					}
					const decodedValue = decoder.decode(value);

					const lines = decodedValue.split('\n');
					const parsedLines = lines.map(line => line.replace(/^data: /, '').trim()).filter(line => line !== '' && line !== '[DONE]');

					if (parsedLines.length > 1 && parsedLines[0].includes('thread.message.delta')) {
						const obj = JSON.parse(parsedLines[1]);
						message += obj.delta.content[0].text.value;
						setResponse(message);
					}
					scrollbarRef.current.scrollToBottom();
				}

				handleGetRun(res.id, prompt, id);
				return;
			})
			.catch(err => console.log('Run thread err: ', err));
	};
	const handleGetRun = async (runId, prompt, threadId) => {
		await fetch(`https://api.openai.com/v1/threads/${threadId}/runs/${runId}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${openaiKey}`,
				'OpenAI-Beta': 'assistants=v2',
			},
		})
			.then(res => res.json())
			.then(res => {
				if (res.status === 'queued' || res.status === 'in_progress') {
					setTimeout(() => {
						handleGetRun(runId, prompt, threadId);
					}, 3000);
				} else {
					handleGetThread(prompt, threadId);
				}

				return;
			})
			.catch(err => console.log('Run thread err: ', err));
	};
	const handleAddMessage = async (prompt, id = threadId) => {
		setThread([...thread, { role: 'assistant', message: response }, { role: 'user', message: question }]);
		setResponse('');
		await fetch(`https://api.openai.com/v1/threads/${id}/messages`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${openaiKey}`,
				'OpenAI-Beta': 'assistants=v2',
			},
			body: JSON.stringify({
				role: 'user',
				content: prompt,
			}),
		})
			.then(res => res.json())
			.then(res => {
				handleRunThread(prompt, id);
				return;
			})
			.catch(err => console.log('Add Message err: ', err));
	};
	const handleSubmit = async (prompt = input) => {
		setDoneGenerating(false);
		setInput('');
		if (isProUser()) {
			setQuestion(prompt);
			if (threadId.length === 0) {
				setResponse('');
				await fetch(`https://api.openai.com/v1/threads`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${openaiKey}`,
						'OpenAI-Beta': 'assistants=v2',
					},
					body: JSON.stringify({
						messages: [
							{
								role: 'user',
								content: prompt,
							},
						],
					}),
				})
					.then(res => res.json())
					.then(res => {
						const { id } = res;
						setResponseLoading(true);
						setThreadId(id);
						handleRunThread(prompt, id);
						setInput('');
						return;
					})
					.catch(err => console.log('Creating thread err: ', err));
			} else {
				setResponseLoading(true);
				handleAddMessage(prompt, threadId);
			}
		} else {
			setResponse(
				'This feature is only available for Pro users. Please sign up for a pro membership in the account settings tab on the dashboard to access this feature'
			);
		}
	};
	const scrollToKey = key => {
		const itemRef = itemRefs.current[key];
		if (itemRef && scrollbarRef.current) {
			scrollbarRef.current.scrollTop(itemRef.offsetTop);
		}
	};

	const parseResponse = text => {
		const parts = [];
		let lastIndex = 0;

		text.replace(verseRegex, (match, book, chapter, verse, endVerse, offset) => {
			if (offset > lastIndex) {
				parts.push(text.slice(lastIndex, offset));
			}
			parts.push(
				<span
					key={offset}
					className='verse-reference'
					onClick={() => handleVerseClick(book, chapter, verse, endVerse)}
					style={{ color: 'blue', cursor: 'pointer' }}
				>
					{match}
				</span>
			);
			lastIndex = offset + match.length;
			return match;
		});

		if (lastIndex < text.length) {
			parts.push(text.slice(lastIndex));
		}

		return parts;
	};

	const handleVerseClick = async (book, chapter, verse, endVerse) => {
		if (book === 'D&C') book = 'Doctrine and Covenants';
		await fetch(`${process.env.REACT_APP_BASE_URL}/verses/getChapterId/${book}/${chapter}`)
			.then(res => res.json())
			.then(res => {
				if (res !== `Chapter doesn't exist`) {
					handleOpenVerses({
						chapterId: res.chapterId,
						initialChapterId: res.chapterId,
						bookId: res.book_id,
						originPaneKey: props.uniqueKey,
						verseNumber: parseInt(verse),
						proUser: isProUser(),
					});
				} else {
					alert(`Sorry, can't open this link at this time.`);
				}
			});
	};

	const mappedThread = thread.map((e, i) => {
		const ref = e => (itemRefs.current[i] = e);
		return e.role === 'user' ? (
			<Question key={i} ref={ref}>
				<NameSpan>You:</NameSpan> {parseResponse(e.message)}
			</Question>
		) : (
			<Response key={i} ref={ref}>
				<NameSpan>Daniel:</NameSpan> {parseResponse(e.message)}
			</Response>
		);
	});
	return (
		<>
			<PaneContainer>
				<TopSection>
					<ClosePaneWrapper>
						<Tooltip content='Close Pane' direction='up' forceDirection={true}>
							<FontAwesomeIcon icon={faTimes} onClick={() => props.closePane(props.id)} />
						</Tooltip>
					</ClosePaneWrapper>
					{/* <Scrollbars autohide> */}
					<TitleDiv {...props.dragHandleProps}>
						<TitleContainer>
							<PaneTitle>AI Assistant - Daniel</PaneTitle>
						</TitleContainer>
						{doneGenerating && <CreateCnBtn onClick={createCN}>Create CN</CreateCnBtn>}
					</TitleDiv>
					<div>
						<VerseTitle>
							{book && `${props.verse.book} ${props.verse.chapter}:${props.verse.verseNumber}`}{' '}
							<PortalWithState closeOnOutsideClick closeOnEsc>
								{({ openPortal, closePortal, isOpen, portal }) => (
									<>
										<InfoSpan onClick={openPortal}>
											<FontAwesomeIcon icon={faInfoCircle} />
										</InfoSpan>
										{portal(
											<div>
												<div
													style={{
														top: 0,
														zIndex: 9999999,
														background: '#ddd',
														width: '100%',
														height: '100%',
														minHeight: '392px',
														opacity: '0.6',
														position: 'absolute',
													}}
												/>
												<DeletePopUpWrapper>
													<DeletePopUpBtnWrapper>
														<p>
															AI Assistant Daniel is helpful, but all answers should be verified by further searching the scriptures.
															This pane cannot be saved on its own so copy/paste out anything you want to save to another note. This
															will be enhanced in the future.
														</p>
														<ClosePopUpBtn
															onClick={() => {
																closePortal();
															}}
														>
															Okay
														</ClosePopUpBtn>
													</DeletePopUpBtnWrapper>
												</DeletePopUpWrapper>
											</div>
										)}
									</>
								)}
							</PortalWithState>
						</VerseTitle>
					</div>
				</TopSection>
				<ResponseContainer>
					<Scrollbars ref={scrollbarRef}>
						<div>
							{mappedThread.reverse()}
							{question.length > 0 && (
								<Question>
									<NameSpan>You:</NameSpan> {question}
								</Question>
							)}
							{!responseLoading ? (
								<Response>
									<NameSpan>Daniel:</NameSpan> {parseResponse(response)}
								</Response>
							) : (
								<LoadingContainer>
									<Loading />
								</LoadingContainer>
							)}
							{doneGenerating && <CopyButton onClick={copyResponse}>Copy Conversation</CopyButton>}
						</div>
					</Scrollbars>
				</ResponseContainer>
				<ConversationContainer>
					{question.length === 0 &&
						(book ? (
							<PromptsContainer>
								<Prompt
									onClick={() => {
										// setLoading(true);
										handleSubmit(`What is the purpose of this chapter?`);
									}}
								>
									What is the purpose of this chapter?
								</Prompt>
								<Prompt
									onClick={() => {
										// setLoading(true);
										handleSubmit(
											`What is the historical context of ${props.verse.book} ${props.verse.chapter}:${props.verse.verseNumber}?`
										);
									}}
								>
									What is the historical context of this verse of scripture?
								</Prompt>
								<Prompt
									onClick={() => {
										// setLoading(true);
										handleSubmit(
											`What is the doctrinal context for the teachings of ${props.verse.book} ${props.verse.chapter}:${props.verse.verseNumber}?`
										);
									}}
								>
									What is the doctrinal context for the teachings of this verse?
								</Prompt>
								<Prompt
									onClick={() => {
										// setLoading(true);
										handleSubmit(
											`What other scriptures and ancient texts can you cross-reference to support ${props.verse.book} ${props.verse.chapter}:${props.verse.verseNumber}?`
										);
									}}
								>
									What other scriptures and ancient texts can you cross-reference to support this verse?
								</Prompt>
								<Prompt
									onClick={() => {
										// setLoading(true);
										handleSubmit(
											`Please provide a comprehensive list of thoughtful questions I should ask about ${props.verse.book} ${props.verse.chapter}:${props.verse.verseNumber}?`
										);
									}}
								>
									Please provide a comprehensive list of thoughtful questions I should ask about this verse.
								</Prompt>
							</PromptsContainer>
						) : (
							<PromptsContainer>
								<Prompt
									onClick={() => {
										// setLoading(true);
										handleSubmit(
											`Please give me 5 topically related verses to begin a study with in the scriptures, as well as 5 apocryphal references for that topic.`
										);
									}}
								>
									Please give me 5 topically related verses to begin a study with in the scriptures, as well as 5 apocryphal references for
									that topic.
								</Prompt>
								<Prompt
									onClick={() => {
										// setLoading(true);
										handleSubmit(`Please give me 10 topically related verses to begin a study with in the scriptures.`);
									}}
								>
									Please give me 10 topically related verses to begin a study with in the scriptures.
								</Prompt>
								<Prompt
									onClick={() => {
										// setLoading(true);
										handleSubmit(`Please give me a deep dive on a topic I choose.`);
									}}
								>
									Please give me a deep dive on a topic I choose.
								</Prompt>
								<Prompt
									onClick={() => {
										// setLoading(true);
										handleSubmit(
											`Please ask me for a verse reference and then provide a comprehensive list of thoughtful questions I should ask it/them.`
										);
									}}
								>
									Please ask me for a verse reference and then provide a comprehensive list of thoughtful questions I should ask it/them.
								</Prompt>
							</PromptsContainer>
						))}
				</ConversationContainer>
				<TextAreaContainer>
					<TextareaAutosize
						placeholder={book ? 'Enter your questions about this verse here.' : 'Enter your questions here.'}
						style={{ backgroundColor: 'white' }}
						className='text-area-wrapper'
						maxRows={20}
						value={input}
						onChange={e => setInput(e.target.value)}
						tabIndex='1'
					/>
					<SubmitButton
						onClick={() => {
							if (input.length > 0) {
								// setLoading(true);
								handleSubmit();
							}
						}}
					>
						<FontAwesomeIcon
							icon={faArrowUp}
							data-tip='Scroll Left'
							style={{
								marginBottom: '7px',
								fontSize: '14px',
								cursor: 'pointer',
							}}
						/>
					</SubmitButton>
				</TextAreaContainer>
			</PaneContainer>
		</>
	);
};

export default ChatGPT;
